import React from "react";
import { Skill } from "./SkillsForm";

interface SkillsRowProps {
  skill: Skill;
  onToggle: (rowId: number, skillId: number, checked: boolean) => void;
  isChecked: boolean;
}

const SkillsRow: React.FC<SkillsRowProps> = ({
  skill,
  onToggle,
  isChecked,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    onToggle(skill.id, skill.skill_id, checked);
  };

  return (
    <li className="flex items-center space-x-3 my-2">
      <div className="w-1/2">
        <span className="text-base">{skill.skill_label}</span>
      </div>
      <div className="flex items-center space-x-2">
        <label className="inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleChange}
            className="sr-only peer"
          />
          <div className="relative w-11 h-6 bg-red-500 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-500"></div>
        </label>
      </div>
    </li>
  );
};

export default SkillsRow;
