import React, { useEffect, useState } from "react";
import SkillsRow from "./SkillsRow";
import useApi from "../hooks/useApi";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export interface Skill {
  id: number;
  skill_label: string;
  skill_id: number;
  type: string;
}

interface SkillsProps {
  skills: { hard_skills?: Skill[]; soft_skills?: Skill[] };
}

const SkillsForm: React.FC<SkillsProps> = ({ skills }) => {
  const { t } = useTranslation();
  const [selectedSkills, setSelectedSkills] = useState<Set<number>>(() => {
    const allSkills = [
      ...(skills.hard_skills || []),
      ...(skills.soft_skills || []),
    ];
    return new Set(allSkills.map((skill) => skill.skill_id));
  });

  const [firstRowId, setFirstRowId] = useState<number | null>(null);

  const { fetchData, apiError, loading } = useApi();

  useEffect(() => {
    // Stocker le premier rowId valide lors du chargement des compétences
    const allSkills = [
      ...(skills.hard_skills || []),
      ...(skills.soft_skills || []),
    ];
    if (allSkills.length > 0) {
      setFirstRowId(allSkills[0].id);
    }
  }, [skills]);

  const handleToggle = async (
    rowId: number,
    skillId: number,
    checked: boolean
  ) => {
    setSelectedSkills((prevSelectedSkills) => {
      const updatedSelectedSkills = new Set(prevSelectedSkills);
      if (checked) {
        updatedSelectedSkills.add(skillId);
      } else {
        updatedSelectedSkills.delete(skillId);
      }
      return updatedSelectedSkills;
    });

    const result = await fetchData(`/api/ai/update-skill-approval/${rowId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        is_approved: checked,
      }),
    });
  };

  const handleSendJobfit = async () => {
    if (firstRowId === null) {
      Swal.fire({
        icon: "error",
        text: "No valid row ID available.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    const result = await fetchData(`/api/ai/send-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        rowId: firstRowId,
      }),
    });

    if (result.success) {
      Swal.fire({
        icon: "success",
        text: t(result.message),
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      Swal.fire({
        icon: "error",
        text: t(result.error),
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  useEffect(() => {
    const displayError = (message: string) => {
      Swal.fire({
        icon: "error",
        text: message,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
    };

    if (apiError) {
      displayError(apiError);
    }
  }, [apiError]);

  return (
    <div className="text-white">
      {(skills.hard_skills || []).length > 0 && (
        <>
          <h2 className="text-3xl font-bold text-[#5DE1E6] uppercase">
            {t("hard_skills")} :
          </h2>
          <div className="my-6">
            <ul>
              {skills.hard_skills?.map((skill) => (
                <SkillsRow
                  key={skill.skill_id}
                  skill={skill}
                  onToggle={handleToggle}
                  isChecked={selectedSkills.has(skill.skill_id)}
                />
              ))}
            </ul>
          </div>
        </>
      )}

      {(skills.soft_skills || []).length > 0 && (
        <>
          <h2 className="text-3xl font-bold text-[#5DE1E6] uppercase mt-10">
            {t("soft_skills")} :
          </h2>
          <div>
            <ul>
              {skills.soft_skills?.map((skill) => (
                <SkillsRow
                  key={skill.skill_id}
                  skill={skill}
                  onToggle={handleToggle}
                  isChecked={selectedSkills.has(skill.skill_id)}
                />
              ))}
            </ul>
          </div>
        </>
      )}

      <div className="w-full flex items-center justify-center mt-20">
        <button
          className="mt-8 bg-[#5DE1E6] text-white py-3 px-6 rounded-lg font-bold uppercase"
          onClick={handleSendJobfit}
        >
          {t("receive_jobfit")}
        </button>
      </div>
    </div>
  );
};

export default SkillsForm;
