import React, { useState } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Form from "./components/Form";
import SkillsForm, { Skill } from "./components/SkillsForm";

const App: React.FC = () => {
  const [skills, setSkills] = useState<{
    hard_skills?: Skill[];
    soft_skills?: Skill[];
  }>({
    hard_skills: [],
    soft_skills: [],
  });

  const handleFormSubmit = (data: {
    hard_skills?: Skill[];
    soft_skills?: Skill[];
  }) => {
    setSkills(data);
  };

  const resetSkills = () => {
    setSkills({ hard_skills: [], soft_skills: [] });
  };

  return (
    <div className="bg-[#1e2c4a] scroll-smooth">
      <div className="max-w-4xl mx-auto">
        <Header onLogoClick={resetSkills} />
        <div className="py-10">
          {skills &&
          ((skills.hard_skills?.length ?? 0) > 0 ||
            (skills.soft_skills?.length ?? 0) > 0) ? (
            <SkillsForm skills={skills} />
          ) : (
            <Form onSubmit={handleFormSubmit} />
          )}
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default App;
