import { useState } from "react";
import axios from "axios";

axios.defaults.timeout = 60000;

const useApi = () => {
  const [apiError, setApiError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async (endpoint: string, options: any = {}) => {
    setLoading(true);
    setApiError(null);

    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_BASE_URL}${endpoint}`,
        method: options.method || "GET",
        headers: options.headers || {},
        data: options.body || null,
      });

      return response.data;
    } catch (error: any) {
      setApiError(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  return { fetchData, apiError, loading };
};

export default useApi;
