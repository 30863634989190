import React from "react";
import { useTranslation } from "react-i18next";
import { logo, fr_flag, en_flag, header_img } from "../assets/images";

interface HeaderProps {
  onLogoClick: () => void;
}

const Header: React.FC<HeaderProps> = ({ onLogoClick }) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <header className="flex flex-col md:flex-row md:justify-between items-center py-4 px-8 space-y-8 md:space-y-0">
        <div className="flex items-center">
          <img
            src={logo}
            alt="proovee.ai logo"
            className="w-52 h-auto cursor-pointer"
            onClick={onLogoClick}
          />
        </div>

        <div className="flex items-center space-x-4">
          <a
            href="#contact-us"
            className="text-white border border-white rounded-full py-1 px-10 hover:bg-white hover:text-[#0f172a] transition"
          >
            {t("contact")}
          </a>

          <div className="flex space-x-2">
            <button onClick={() => changeLanguage("fr")}>
              <img src={fr_flag} alt="Français" className="w-8 h-8" />
            </button>
            <button onClick={() => changeLanguage("en")}>
              <img src={en_flag} alt="English" className="w-8 h-8" />
            </button>
          </div>
        </div>
      </header>

      <div className="flex flex-col items-center justify-center relative mt-10 md:mt-0 px-5">
        <img
          src={header_img}
          alt="Decorative element"
          className="w-4/5 h-auto"
        />
        <h1 className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full text-white text-center text-4xl md:text-5xl font-bold leading-tight drop-shadow-2xl">
          {t("header_desc_1")}
          <br />
          {t("header_desc_2")}
        </h1>
      </div>
    </>
  );
};

export default Header;
