import { Spinner } from "@radix-ui/themes";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import useApi from "../hooks/useApi";
import { Skill } from "./SkillsForm";

interface FormProps {
  onSubmit: (skills: { hard_skills?: Skill[]; soft_skills?: Skill[] }) => void;
}

const Form: React.FC<FormProps> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const [jobDescription, setJobDescription] = useState("");
  const [email, setEmail] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [notRobot, setNotRobot] = useState(false);
  const [error, setError] = useState("");
  const { fetchData, apiError, loading } = useApi();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!jobDescription) {
      setError(t("job_description_error"));
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !emailPattern.test(email)) {
      setError(t("email_error"));
      return;
    }

    if (!notRobot) {
      setError(t("not_robot_error"));
      return;
    }

    if (!acceptTerms) {
      setError(t("valid_terms_error"));
      return;
    }

    const result = await fetchData("/api/ai/request", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        job_description: jobDescription,
        email,
        accept_terms: acceptTerms,
        not_robot: notRobot,
      }),
    });

    if (result) {
      onSubmit(result);
    }

    setError("");
  };

  useEffect(() => {
    const displayError = (message: string) => {
      Swal.fire({
        icon: "error",
        text: t(message),
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
    };

    if (error) {
      displayError(error);
      setError("");
    }

    if (apiError) {
      displayError(apiError);
    }
  }, [error, apiError]);

  return (
    <form
      onSubmit={handleSubmit}
      className="py-10 md:py-20 px-8 rounded-md space-y-6 text-center"
    >
      <div className="border-2 border-white rounded-lg p-6 bg-black text-[#5DE1E6] text-lg">
        <textarea
          className="w-full bg-transparent outline-none resize-none font-medium text-[#5DE1E6] placeholder-[#5DE1E6]"
          rows={10}
          placeholder={t("job_description")}
          value={jobDescription}
          onChange={(e) => setJobDescription(e.target.value)}
        ></textarea>
      </div>

      <div className="flex items-center border-2 border-white rounded-lg p-4 bg-black">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-8 h-8 text-white mr-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 8l7.218 4.512a2.5 2.5 0 002.564 0L20 8m0 0l-7.218-4.512a2.5 2.5 0 00-2.564 0L4 8m16 0v8.5a2.5 2.5 0 01-2.5 2.5h-11A2.5 2.5 0 014 16.5V8"
          />
        </svg>
        <input
          type="text"
          placeholder={t("email")}
          className="bg-transparent w-full outline-none text-[#5DE1E6] text-lg placeholder-[#5DE1E6]"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="flex items-center">
        <input
          type="checkbox"
          id="terms"
          className="w-6 h-6 text-[#5DE1E6] border-2 border-white rounded focus:ring-0 accent-[#5DE1E6]"
          checked={acceptTerms}
          onChange={(e) => setAcceptTerms(e.target.checked)}
        />
        <label
          htmlFor="terms"
          className="ml-4 text-[#5DE1E6] text-lg text-left"
        >
          {t("valid_terms")}
        </label>
      </div>

      <div className="flex items-center">
        <input
          type="checkbox"
          id="not_robot"
          className="w-6 h-6 text-[#5DE1E6] border-2 border-white rounded focus:ring-0 accent-[#5DE1E6]"
          checked={notRobot}
          onChange={(e) => setNotRobot(e.target.checked)}
        />
        <label
          htmlFor="not_robot"
          className="ml-4 text-[#5DE1E6] text-lg text-left"
        >
          {t("not_robot")}
        </label>
      </div>

      <div
        className={`${
          !loading ? "pt-10 md:pt-20" : "pt-5 md:pt-10"
        } flex items-center justify-center`}
      >
        {loading ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 200"
            className="w-32"
          >
            <circle
              fill="#FFFFFF"
              stroke="#FFFFFF"
              strokeWidth="12"
              r="15"
              cx="35"
              cy="100"
            >
              <animate
                attributeName="cx"
                calcMode="spline"
                dur="2"
                values="35;165;165;35;35"
                keySplines="0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1"
                repeatCount="indefinite"
                begin="0"
              ></animate>
            </circle>
            <circle
              fill="#FFFFFF"
              stroke="#FFFFFF"
              strokeWidth="12"
              opacity=".8"
              r="15"
              cx="35"
              cy="100"
            >
              <animate
                attributeName="cx"
                calcMode="spline"
                dur="2"
                values="35;165;165;35;35"
                keySplines="0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1"
                repeatCount="indefinite"
                begin="0.05"
              ></animate>
            </circle>
            <circle
              fill="#FFFFFF"
              stroke="#FFFFFF"
              strokeWidth="12"
              opacity=".6"
              r="15"
              cx="35"
              cy="100"
            >
              <animate
                attributeName="cx"
                calcMode="spline"
                dur="2"
                values="35;165;165;35;35"
                keySplines="0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1"
                repeatCount="indefinite"
                begin=".1"
              ></animate>
            </circle>
            <circle
              fill="#FFFFFF"
              stroke="#FFFFFF"
              strokeWidth="12"
              opacity=".4"
              r="15"
              cx="35"
              cy="100"
            >
              <animate
                attributeName="cx"
                calcMode="spline"
                dur="2"
                values="35;165;165;35;35"
                keySplines="0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1"
                repeatCount="indefinite"
                begin=".15"
              ></animate>
            </circle>
            <circle
              fill="#FFFFFF"
              stroke="#FFFFFF"
              strokeWidth="12"
              opacity=".2"
              r="15"
              cx="35"
              cy="100"
            >
              <animate
                attributeName="cx"
                calcMode="spline"
                dur="2"
                values="35;165;165;35;35"
                keySplines="0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1"
                repeatCount="indefinite"
                begin=".2"
              ></animate>
            </circle>
          </svg>
        ) : (
          <button
            type="submit"
            className="px-32 py-8 bg-[#5DE1E6] text-white font-medium rounded-lg transition uppercase text-xl"
          >
            {t("start_analysis")}
          </button>
        )}
      </div>
    </form>
  );
};

export default Form;
