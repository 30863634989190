import React from "react";
import { useTranslation } from "react-i18next";
import { logo_proovee } from "../assets/images";

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer className="py-5 md:py-12 px-8">
      <div className="flex flex-col md:flex-row md:justify-between md:items-center max-w-6xl md:mx-auto space-y-8 md:space-y-0">
        <div className="w-full flex items-center justify-center md:w-auto md:block">
          <img src={logo_proovee} alt="proovee logo" className="w-60 h-auto" />
        </div>

        <div className="text-white" id="contact-us">
          <h2 className="text-5xl font-bold mb-4">{t("contact_us")}</h2>
          <ul className="space-y-2 text-base font-light">
            <li>
              <a href="https://proovee.com/">proovee.com</a>
            </li>
            <li className="pt-5">1 rue de Stockholm</li>
            <li>75008 Paris</li>
            <li>France</li>
            <li className="pt-5">TVA : FR08880963</li>
            <li className="pt-5">
              <a href="mailto:hello@proovee.com" className="font-semibold">
                hello@proovee.com
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
